import { extendTheme, theme } from '@digital-retail/design-system';

import { theme as falabella } from '@digital-retail/design-system-theme-falabella';

export const getTheme = (rebrandingModifier, override = {}) => {
  if (rebrandingModifier === '' || !rebrandingModifier) {
    return falabella;
  }
  return extendTheme(override, theme);
};
