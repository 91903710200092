import { loadDynamicYield } from './dynamicYield/index';
import loadSurveyScript from './survey/medallia';
import loadDatadog from './dataDog/index';

const loadExternalDependencies = async ({
  tenant = 'cl',
  isDYEnabled = false,
  allowedSIS = false
}) => {
  if (isDYEnabled && allowedSIS) {
    loadDynamicYield({ tenant });
  }
  loadSurveyScript();
  loadDatadog();
};

export default loadExternalDependencies;
