import { createScriptTag, appendBeforeBodyEnd } from '../../utils/DOMUtil';
import constants from '../../config/constants';

const loadSurveyScript = () => {
  const dynamicScript = createScriptTag({
    src: constants.MEDALLIA,
    async: true
  });
  appendBeforeBodyEnd(dynamicScript);
};

export default loadSurveyScript;
