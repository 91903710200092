import getNextConfig from 'next/config';
import { datadogRum } from '@datadog/browser-rum';

const nextConfig = getNextConfig();

const loadDatadog = () => {
  const {
    DATADOG_APPLICATION_NAME,
    DATADOG_ENABLED,
    DATADOG_APPLICATION_ID = '',
    DATADOG_APPLICATION_TOKEN = '',
    DATADOG_SITE = 'datadoghq.com',
    ENVIRONMENT,
    RUM_SAMPLE_RATE = Number(process.env.RUM_SAMPLE_RATE) || 30
  } = nextConfig && nextConfig.publicRuntimeConfig;

  if (
    DATADOG_ENABLED &&
    DATADOG_APPLICATION_ID !== '' &&
    DATADOG_APPLICATION_TOKEN !== ''
  ) {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_APPLICATION_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_APPLICATION_NAME,
      env: ENVIRONMENT,
      sampleRate: RUM_SAMPLE_RATE,
      trackInteractions: true,
      sessionReplaySampleRate: 0
    });
  }
};

export default loadDatadog;
